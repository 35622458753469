import { toJpeg, toPng } from 'html-to-image';
import React, { useRef, useState } from 'react';
import '../../style/global.css';
import ImgCrop from 'antd-img-crop';
import { Upload, UploadFile } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';

type FieldType = {
  phone: string;
};

const getSrcFromFile = (file: UploadFile): Promise<string> => {
  return new Promise((resolve) => {
    if (!file.originFileObj) {
      resolve('');
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file.originFileObj as Blob);
    reader.onload = () => resolve(reader.result as string);
  });
};

const GetPhone: React.FC = () => {
  // Sử dụng useRef để tham chiếu đến phần tử DOM cần export
  const domRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [imageUrl, setImageUrl] = useState<string | null>(null); // State để lưu URL của ảnh

  const onChange = (info: UploadChangeParam<UploadFile>) => {
    const newFileList = info.fileList.slice(-1); // Giữ lại một ảnh duy nhất
    setFileList(newFileList);

    if (newFileList.length > 0) {
      const file = newFileList[0];
      if (file.response) {
        // Nếu file đã tải lên và có phản hồi từ server, lấy URL từ đó
        setImageUrl(file.response.url); // Giả sử phản hồi từ server chứa URL
      } else if (file.originFileObj) {
        // Nếu file chưa tải lên hoặc không có phản hồi từ server, sử dụng URL tạm
        getSrcFromFile(file).then((url) => setImageUrl(url));
      }
    } else {
      setImageUrl(null); // Xóa URL nếu không có ảnh nào
    }
  };

  const onPreview = async (file: UploadFile) => {
    const src = file.url || (await getSrcFromFile(file));
    const imgWindow = window.open(src);

    if (imgWindow) {
      const image = new Image();
      image.src = src;
      imgWindow.document.write(image.outerHTML);
    } else {
      window.location.href = src;
    }
  };

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  // Hàm xử lý khi bấm nút export
  const handleDownloadImage = () => {
    if (!domRef.current) {
      return;
    }

    if(!imageUrl){
      alert('Vui lòng chọn ảnh')
      return;
    }

    if(!name){
      alert('Vui lòng nhập tên')
      return;
    }


    const removeAccents = (str: string) => {
      const map: { [key: string]: string } = {
        'à': 'a', 'á': 'a', 'ả': 'a', 'ã': 'a', 'ạ': 'a',
        'ă': 'a', 'ằ': 'a', 'ắ': 'a', 'ẳ': 'a', 'ẵ': 'a', 'ặ': 'a',
        'â': 'a', 'ầ': 'a', 'ấ': 'a', 'ẩ': 'a', 'ẫ': 'a', 'ậ': 'a',
        'è': 'e', 'é': 'e', 'ẻ': 'e', 'ẽ': 'e', 'ẹ': 'e',
        'ê': 'e', 'ề': 'e', 'ế': 'e', 'ể': 'e', 'ễ': 'e', 'ệ': 'e',
        'ì': 'i', 'í': 'i', 'ỉ': 'i', 'ĩ': 'i', 'ị': 'i',
        'ò': 'o', 'ó': 'o', 'ỏ': 'o', 'õ': 'o', 'ọ': 'o',
        'ô': 'o', 'ồ': 'o', 'ố': 'o', 'ổ': 'o', 'ỗ': 'o', 'ộ': 'o',
        'ơ': 'o', 'ờ': 'o', 'ớ': 'o', 'ở': 'o', 'ỡ': 'o', 'ợ': 'o',
        'ù': 'u', 'ú': 'u', 'ủ': 'u', 'ũ': 'u', 'ụ': 'u',
        'ư': 'u', 'ừ': 'u', 'ứ': 'u', 'ử': 'u', 'ữ': 'u', 'ự': 'u',
        'ỳ': 'y', 'ý': 'y', 'ỷ': 'y', 'ỹ': 'y', 'ỵ': 'y',
        'đ': 'd',
        // Include any additional mappings if needed
      };
      
      return str.split('').map((char: string )=> map[char] || char).join('');
    };
    
    const convertNameToSlug = (name: string) => {
      const nameWithoutAccents = removeAccents(name);
      return nameWithoutAccents
        .toLowerCase()        // Chuyển tất cả ký tự thành chữ thường
        .replace(/\s+/g, '-') // Thay khoảng trắng bằng dấu gạch ngang
        .replace(/[^a-z0-9\-]/g, ''); // Xóa tất cả ký tự đặc biệt ngoại trừ dấu gạch ngang và số
    };

    // Sử dụng hàm toPng để chuyển DOM thành ảnh PNG
    toJpeg(domRef?.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.href = dataUrl;
        link.download = `${convertNameToSlug(name)}.png`;
        link.click();
      })
      .catch((err) => {
        console.error('Export failed:', err);
      });
  };

  

  return (
    <div>
      
     <div style={{
            position: 'absolute',
            top: '-1500px'
          }}>
     <div
          ref={domRef}
          style={{
            width: '850px',
            position: 'relative',
          }}
        >
          <div
            style={{
              width: '850px',
              height: '450px',
              backgroundImage: 'url("/background.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <img className='avatar-real' src={imageUrl || ''} alt="" />
            <h1 className='name-real' style={{backgroundImage: 'url("/backgroundwrap.jpg")',}}>{name}</h1>
          </div>
        </div>
     </div>
      <div className='box-content'>
        <div
          // ref={domRef}
          style={{
            position: 'relative',
            width: 'fit-content'
          }}
        >
          <div
            style={{
              width: '850px',
              height: '450px',
              backgroundImage: 'url("/background.jpg")',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          >
            <img className='avatar' src={imageUrl || ''} alt="" />
            <h1 className='name' style={{backgroundImage: 'url("/backgroundwrap.jpg")',}}>{name}</h1>
          </div>
        </div>
        <div className='box-update'>
                    <div>
            <h2 className='title-upload'>Thay ảnh</h2>
          </div>
<ImgCrop showGrid rotationSlider aspectSlider showReset>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76" // Đường dẫn giả lập tải file
          listType="picture-card"
          fileList={fileList}
          onChange={onChange}
          onPreview={onPreview}
          maxCount={1} // Giới hạn số lượng ảnh tải lên
        >
          {fileList.length === 0 && "+ Upload"}
        </Upload>
      </ImgCrop>

          <div style={{
            marginTop: 20
          }}>
            <h2>Thay tên</h2>
            <input
              maxLength={25}
              type="text"
              placeholder="Nhập tên"
              value={name}
              onChange={handleNameChange}
              className="name-input"
            />
          </div>
          <div className='box-btn'>
          <button onClick={handleDownloadImage} className='buttonExport'>
            Tải ảnh
          </button>
          </div>
        </div>


      </div>
    </div>
  )
};

export default GetPhone;